// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-about-us-jsx": () => import("./../../../src/pages/about/about-us.jsx" /* webpackChunkName: "component---src-pages-about-about-us-jsx" */),
  "component---src-pages-about-board-of-directors-jsx": () => import("./../../../src/pages/about/board-of-directors.jsx" /* webpackChunkName: "component---src-pages-about-board-of-directors-jsx" */),
  "component---src-pages-about-branches-jsx": () => import("./../../../src/pages/about/branches.jsx" /* webpackChunkName: "component---src-pages-about-branches-jsx" */),
  "component---src-pages-about-ceos-profile-jsx": () => import("./../../../src/pages/about/ceo's-profile.jsx" /* webpackChunkName: "component---src-pages-about-ceos-profile-jsx" */),
  "component---src-pages-about-chairmans-profile-jsx": () => import("./../../../src/pages/about/chairman's-profile.jsx" /* webpackChunkName: "component---src-pages-about-chairmans-profile-jsx" */),
  "component---src-pages-about-chief-consultants-profile-jsx": () => import("./../../../src/pages/about/chief-consultant's-profile.jsx" /* webpackChunkName: "component---src-pages-about-chief-consultants-profile-jsx" */),
  "component---src-pages-about-citizen-charter-jsx": () => import("./../../../src/pages/about/citizen-charter.jsx" /* webpackChunkName: "component---src-pages-about-citizen-charter-jsx" */),
  "component---src-pages-about-credit-rating-jsx": () => import("./../../../src/pages/about/credit-rating.jsx" /* webpackChunkName: "component---src-pages-about-credit-rating-jsx" */),
  "component---src-pages-about-csr-jsx": () => import("./../../../src/pages/about/csr.jsx" /* webpackChunkName: "component---src-pages-about-csr-jsx" */),
  "component---src-pages-about-divisions-depts-jsx": () => import("./../../../src/pages/about/divisions-depts.jsx" /* webpackChunkName: "component---src-pages-about-divisions-depts-jsx" */),
  "component---src-pages-about-download-form-jsx": () => import("./../../../src/pages/about/download-form.jsx" /* webpackChunkName: "component---src-pages-about-download-form-jsx" */),
  "component---src-pages-about-management-jsx": () => import("./../../../src/pages/about/management.jsx" /* webpackChunkName: "component---src-pages-about-management-jsx" */),
  "component---src-pages-about-organogram-jsx": () => import("./../../../src/pages/about/organogram.jsx" /* webpackChunkName: "component---src-pages-about-organogram-jsx" */),
  "component---src-pages-about-re-insurers-jsx": () => import("./../../../src/pages/about/re-insurers.jsx" /* webpackChunkName: "component---src-pages-about-re-insurers-jsx" */),
  "component---src-pages-about-tender-notice-jsx": () => import("./../../../src/pages/about/tender-notice.jsx" /* webpackChunkName: "component---src-pages-about-tender-notice-jsx" */),
  "component---src-pages-about-test-page-jsx": () => import("./../../../src/pages/about/testPage.jsx" /* webpackChunkName: "component---src-pages-about-test-page-jsx" */),
  "component---src-pages-about-vision-mission-jsx": () => import("./../../../src/pages/about/vision-&-mission.jsx" /* webpackChunkName: "component---src-pages-about-vision-mission-jsx" */),
  "component---src-pages-agm-jsx": () => import("./../../../src/pages/AGM.jsx" /* webpackChunkName: "component---src-pages-agm-jsx" */),
  "component---src-pages-blog-blog-1-jsx": () => import("./../../../src/pages/blog/blog1.jsx" /* webpackChunkName: "component---src-pages-blog-blog-1-jsx" */),
  "component---src-pages-blog-blog-2-jsx": () => import("./../../../src/pages/blog/blog2.jsx" /* webpackChunkName: "component---src-pages-blog-blog-2-jsx" */),
  "component---src-pages-blog-blog-3-jsx": () => import("./../../../src/pages/blog/blog3.jsx" /* webpackChunkName: "component---src-pages-blog-blog-3-jsx" */),
  "component---src-pages-blog-blog-4-jsx": () => import("./../../../src/pages/blog/blog4.jsx" /* webpackChunkName: "component---src-pages-blog-blog-4-jsx" */),
  "component---src-pages-blog-left-sidebar-jsx": () => import("./../../../src/pages/blog-left-sidebar.jsx" /* webpackChunkName: "component---src-pages-blog-left-sidebar-jsx" */),
  "component---src-pages-blog-right-sidebar-jsx": () => import("./../../../src/pages/blog-right-sidebar.jsx" /* webpackChunkName: "component---src-pages-blog-right-sidebar-jsx" */),
  "component---src-pages-case-studies-jsx": () => import("./../../../src/pages/case-studies.jsx" /* webpackChunkName: "component---src-pages-case-studies-jsx" */),
  "component---src-pages-claims-notification-jsx": () => import("./../../../src/pages/claims-notification.jsx" /* webpackChunkName: "component---src-pages-claims-notification-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-corporate-governance-guidelines-jsx": () => import("./../../../src/pages/corporate-governance-guidelines.jsx" /* webpackChunkName: "component---src-pages-corporate-governance-guidelines-jsx" */),
  "component---src-pages-directors-report-jsx": () => import("./../../../src/pages/directors-report.jsx" /* webpackChunkName: "component---src-pages-directors-report-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-financial-reports-annual-reports-jsx": () => import("./../../../src/pages/financial-reports/annual-reports.jsx" /* webpackChunkName: "component---src-pages-financial-reports-annual-reports-jsx" */),
  "component---src-pages-financial-reports-jsx": () => import("./../../../src/pages/financial-reports.jsx" /* webpackChunkName: "component---src-pages-financial-reports-jsx" */),
  "component---src-pages-financial-reports-quarterly-reports-first-quarter-reports-jsx": () => import("./../../../src/pages/financial-reports/quarterly-reports/first-quarter-reports.jsx" /* webpackChunkName: "component---src-pages-financial-reports-quarterly-reports-first-quarter-reports-jsx" */),
  "component---src-pages-financial-reports-quarterly-reports-second-quarter-reports-jsx": () => import("./../../../src/pages/financial-reports/quarterly-reports/second-quarter-reports.jsx" /* webpackChunkName: "component---src-pages-financial-reports-quarterly-reports-second-quarter-reports-jsx" */),
  "component---src-pages-financial-reports-quarterly-reports-third-quarter-reports-jsx": () => import("./../../../src/pages/financial-reports/quarterly-reports/third-quarter-reports.jsx" /* webpackChunkName: "component---src-pages-financial-reports-quarterly-reports-third-quarter-reports-jsx" */),
  "component---src-pages-gallery-awards-jsx": () => import("./../../../src/pages/gallery/awards.jsx" /* webpackChunkName: "component---src-pages-gallery-awards-jsx" */),
  "component---src-pages-gallery-celebration-1st-insurance-day-jsx": () => import("./../../../src/pages/gallery/celebration-1st-insurance-day.jsx" /* webpackChunkName: "component---src-pages-gallery-celebration-1st-insurance-day-jsx" */),
  "component---src-pages-gallery-jatyio-bima-dibosh-2021-jsx": () => import("./../../../src/pages/gallery/jatyio-bima-dibosh-2021.jsx" /* webpackChunkName: "component---src-pages-gallery-jatyio-bima-dibosh-2021-jsx" */),
  "component---src-pages-gallery-new-album-jsx": () => import("./../../../src/pages/gallery/newAlbum.jsx" /* webpackChunkName: "component---src-pages-gallery-new-album-jsx" */),
  "component---src-pages-gallery-others-jsx": () => import("./../../../src/pages/gallery/others.jsx" /* webpackChunkName: "component---src-pages-gallery-others-jsx" */),
  "component---src-pages-gallery-photo-gallery-jsx": () => import("./../../../src/pages/gallery/photo-gallery.jsx" /* webpackChunkName: "component---src-pages-gallery-photo-gallery-jsx" */),
  "component---src-pages-gallery-rally-on-1st-insurance-day-jsx": () => import("./../../../src/pages/gallery/rally-on-1st-insurance-day.jsx" /* webpackChunkName: "component---src-pages-gallery-rally-on-1st-insurance-day-jsx" */),
  "component---src-pages-gallery-sample-gallery-page-jsx": () => import("./../../../src/pages/gallery/sampleGalleryPage.jsx" /* webpackChunkName: "component---src-pages-gallery-sample-gallery-page-jsx" */),
  "component---src-pages-gallery-video-gallery-jsx": () => import("./../../../src/pages/gallery/video-gallery.jsx" /* webpackChunkName: "component---src-pages-gallery-video-gallery-jsx" */),
  "component---src-pages-gallery-virtual-bm-conference-2020-jsx": () => import("./../../../src/pages/gallery/virtual-bm-conference-2020.jsx" /* webpackChunkName: "component---src-pages-gallery-virtual-bm-conference-2020-jsx" */),
  "component---src-pages-important-links-jsx": () => import("./../../../src/pages/important-links.jsx" /* webpackChunkName: "component---src-pages-important-links-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-info-agent-info-jsx": () => import("./../../../src/pages/info/agent-info.jsx" /* webpackChunkName: "component---src-pages-info-agent-info-jsx" */),
  "component---src-pages-info-bod-executives-jsx": () => import("./../../../src/pages/info/bod-executives.jsx" /* webpackChunkName: "component---src-pages-info-bod-executives-jsx" */),
  "component---src-pages-info-claim-info-jsx": () => import("./../../../src/pages/info/claim-info.jsx" /* webpackChunkName: "component---src-pages-info-claim-info-jsx" */),
  "component---src-pages-mujib-corner-jsx": () => import("./../../../src/pages/Mujib-Corner.jsx" /* webpackChunkName: "component---src-pages-mujib-corner-jsx" */),
  "component---src-pages-mujib-corner-mujib-notices-jsx": () => import("./../../../src/pages/mujib-corner/mujib-notices.jsx" /* webpackChunkName: "component---src-pages-mujib-corner-mujib-notices-jsx" */),
  "component---src-pages-mujib-corner-mujib-photo-gallery-jsx": () => import("./../../../src/pages/mujib-corner/mujib-photo-gallery.jsx" /* webpackChunkName: "component---src-pages-mujib-corner-mujib-photo-gallery-jsx" */),
  "component---src-pages-mujib-corner-mujib-video-gallery-jsx": () => import("./../../../src/pages/mujib-corner/mujib-video-gallery.jsx" /* webpackChunkName: "component---src-pages-mujib-corner-mujib-video-gallery-jsx" */),
  "component---src-pages-news-archive-jsx": () => import("./../../../src/pages/newsArchive.jsx" /* webpackChunkName: "component---src-pages-news-archive-jsx" */),
  "component---src-pages-our-products-jsx": () => import("./../../../src/pages/our-products.jsx" /* webpackChunkName: "component---src-pages-our-products-jsx" */),
  "component---src-pages-price-sensetive-info-jsx": () => import("./../../../src/pages/price-sensetive-info.jsx" /* webpackChunkName: "component---src-pages-price-sensetive-info-jsx" */),
  "component---src-pages-products-engineering-insurance-jsx": () => import("./../../../src/pages/products/engineering-insurance.jsx" /* webpackChunkName: "component---src-pages-products-engineering-insurance-jsx" */),
  "component---src-pages-products-fire-insurance-jsx": () => import("./../../../src/pages/products/fire-insurance.jsx" /* webpackChunkName: "component---src-pages-products-fire-insurance-jsx" */),
  "component---src-pages-products-health-insurance-jsx": () => import("./../../../src/pages/products/health-insurance.jsx" /* webpackChunkName: "component---src-pages-products-health-insurance-jsx" */),
  "component---src-pages-products-liability-insurance-jsx": () => import("./../../../src/pages/products/liability-insurance.jsx" /* webpackChunkName: "component---src-pages-products-liability-insurance-jsx" */),
  "component---src-pages-products-marine-hull-insurance-jsx": () => import("./../../../src/pages/products/marine-hull-insurance.jsx" /* webpackChunkName: "component---src-pages-products-marine-hull-insurance-jsx" */),
  "component---src-pages-products-marine-insurance-jsx": () => import("./../../../src/pages/products/marine-insurance.jsx" /* webpackChunkName: "component---src-pages-products-marine-insurance-jsx" */),
  "component---src-pages-products-miscellaneous-insurance-jsx": () => import("./../../../src/pages/products/miscellaneous-insurance.jsx" /* webpackChunkName: "component---src-pages-products-miscellaneous-insurance-jsx" */),
  "component---src-pages-products-motor-insurance-jsx": () => import("./../../../src/pages/products/motor-insurance.jsx" /* webpackChunkName: "component---src-pages-products-motor-insurance-jsx" */),
  "component---src-pages-products-overseas-mediclaim-insurance-jsx": () => import("./../../../src/pages/products/overseas-mediclaim-insurance.jsx" /* webpackChunkName: "component---src-pages-products-overseas-mediclaim-insurance-jsx" */),
  "component---src-pages-quarterly-reports-jsx": () => import("./../../../src/pages/quarterly-reports.jsx" /* webpackChunkName: "component---src-pages-quarterly-reports-jsx" */),
  "component---src-pages-sample-page-copy-jsx": () => import("./../../../src/pages/samplePage copy.jsx" /* webpackChunkName: "component---src-pages-sample-page-copy-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-share-price-jsx": () => import("./../../../src/pages/share-price.jsx" /* webpackChunkName: "component---src-pages-share-price-jsx" */),
  "component---src-pages-shareholders-info-jsx": () => import("./../../../src/pages/shareholders-info.jsx" /* webpackChunkName: "component---src-pages-shareholders-info-jsx" */),
  "component---src-pages-shareholding-structure-jsx": () => import("./../../../src/pages/shareholding-structure.jsx" /* webpackChunkName: "component---src-pages-shareholding-structure-jsx" */),
  "component---src-templates-case-studies-details-index-jsx": () => import("./../../../src/templates/case-studies-details/index.jsx" /* webpackChunkName: "component---src-templates-case-studies-details-index-jsx" */),
  "component---src-templates-categories-post-index-jsx": () => import("./../../../src/templates/categories-post/index.jsx" /* webpackChunkName: "component---src-templates-categories-post-index-jsx" */),
  "component---src-templates-services-details-index-jsx": () => import("./../../../src/templates/services-details/index.jsx" /* webpackChunkName: "component---src-templates-services-details-index-jsx" */),
  "component---src-templates-single-post-index-jsx": () => import("./../../../src/templates/single-post/index.jsx" /* webpackChunkName: "component---src-templates-single-post-index-jsx" */),
  "component---src-templates-tag-post-index-jsx": () => import("./../../../src/templates/tag-post/index.jsx" /* webpackChunkName: "component---src-templates-tag-post-index-jsx" */)
}

